.checkout-page {
  width: 1140px;
  max-width: calc(100% - 1vw - 1vw);
  min-height: calc(100vh - 455px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 auto;
  padding: 1vw;
}

.checkout-page.empty-height {
  height: calc(100vh - 97px - 116.69px - 358px - 1vw - 1vw);
}

.checkout-page .skeletons {
  width: 1140px;
  max-width: calc(100%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin: 0 auto;
}

.checkout-page .skeletons .skeletons__form {
  width: calc(60% - 8px);
  height: 0;
  padding-bottom: 50%;
  background-color: #DDD;
}

.checkout-page .skeletons .skeletons__basket {
  width: calc(40% - 8px);
  height: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 25%;
  background-color: #DDD;
}

.checkout-page .shipping-details {
  width: calc(60% - 8px);
  border: 1px solid #DDD;
  background-color: #FFF;
}

.checkout-page .shipping-details .header {
  width: calc(100% - 16px - 16px);
  padding: 16px;
  border-bottom: 1px solid #DDD;
  font-size: 18px;
}

.checkout-page .shipping-details .body {
  width: calc(100% - 16px - 16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 16px;
}

.checkout-page .shipping-details .body .input {
  width: calc(50% - 16px - 16px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding: 16px;
}

.checkout-page .shipping-details .body .input label {
  display: none;
}

.checkout-page .shipping-details .body .input.checkbox > div svg, .checkout-page .shipping-details .body .input.checkbox > div span {
  cursor: pointer;
  font-size: 18px;
  margin-top: 8px;
}

.checkout-page .shipping-details .body .input span {
  font-size: 14px;
}

.checkout-page .shipping-details .body .input input {
  outline: none;
  border: none;
  width: 100%;
  margin-top: 8px;
  padding: 16px 0;
  padding-bottom: 4px;
  font-size: 18px;
  border-bottom: 1px solid #333;
}

.checkout-page .shipping-details .body .input textarea {
  outline: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #333;
  margin-top: 8px;
  padding: 16px 0;
  padding-bottom: 10px;
  font-size: 14px;
}

.checkout-page .shipping-details .footer button {
  width: calc(100% - 16px - 16px);
  margin: 16px;
}

.checkout-page .basket {
  width: calc(40% - 8px - 16px - 16px);
  -ms-flex-item-align: start;
      align-self: flex-start;
  border: 1px solid #DDD;
  padding: 16px;
  background-color: #FFF;
}

.checkout-page .basket .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 16px;
}

.checkout-page .basket .item:nth-of-type(1) {
  margin-top: 0;
}

.checkout-page .basket .item .image {
  width: 25%;
  height: 0;
  padding-bottom: 25%;
  background-size: cover;
  background-position: center bottom;
}

.checkout-page .basket .item .details {
  width: 80%;
  padding: 8px;
  font-size: 14px;
}

.checkout-page .basket .item .details .name {
  font-family: 'Noto Sans Bold';
}

.checkout-page .basket .item .details .name a {
  text-decoration: none;
  color: #333;
}

.checkout-page .basket .item .details .quantity {
  font-size: 12px;
}

.checkout-page .basket .item .details button {
  margin-top: 8px;
}

.checkout-page .basket .subtotal {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 16px;
}

.checkout-page .basket .taxes {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 4px;
  margin-bottom: 16px;
}

.checkout-page .basket .grandtotal {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  padding-top: 16px;
  border-top: 1px solid #DDD;
}

.checkout-page .order-sent-message {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #BBB;
  font-size: 30px;
  font-family: 'Noto Sans Bold';
}

.checkout-page .order-sent-message svg {
  font-size: 64px;
}

@media screen and (max-width: 991px) {
  .checkout-page {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .checkout-page .shipping-details {
    width: 100%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-top: 8px;
  }
  .checkout-page .basket {
    width: calc(100% - 16px - 16px);
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}

@media screen and (max-width: 767px) {
  .checkout-page .shipping-details .body .input {
    width: calc(100% - 16px - 16px);
  }
}
/*# sourceMappingURL=CheckoutPage.css.map */